// To comile this file with the includes below you will need gulp-include https://www.npmjs.com/package/gulp-include

// Imports
/**
 * SVG handling
 */
if (!Modernizr.svg){

   // Get all img tag of the document and create variables
   var i=document.getElementsByTagName("img"),j,y;

   // For each img tag
   for(j = i.length ; j-- ; ){
	   y = i[j].src;
	   // If filenames ends with SVG
     if(y.indexOf("?") > -1){
       y = y.split("?")[0];
     }
	   if( y.match(/svg$/) ){

	   	if (i[j].getAttribute('data-svg-fallback')) {
	   	i[j].src = i[j].getAttribute('data-svg-fallback');
	   	} else {
	   // Replace "svg" by "png"
	   i[j].src = y.slice(0,-3) + 'png';
	   	}
	   }
   }
}

function formValidation(){
    var validateForm = function() {
      var $forms = $("form.validate");
      if ($forms.length){
        grecaptcha.ready(function () {
          console.log('Recaptcha Ready');
          $forms.each(function(i, e) {
            var $form = $(e);
            $form.validate({
              errorPlacement: function(error, inputElement) {
                  if(inputElement[0].type === "checkbox" || inputElement[0].type === "radio"){
                      error.insertAfter(inputElement[0].parentElement);
                  } else{
                      error.insertAfter(inputElement);
                  }
              },
              focusInvalid: true,
              invalidHandler: function(event, validator) {
                  // position of error - the height of the nav - a bit of padding
                  if($form.attr("id") != "callBackForm"){
                      var firstErrorPoistion = ($(validator.errorList[0].element).offset().top-$("#fixed_header").outerHeight(true,true)-40);
                      TweenLite.to(window, 0.5,{
                          scrollTo: firstErrorPoistion
                      });
                  }
              },
              submitHandler: function(form) {
                grecaptcha.execute('6Ldb_sIbAAAAANPZnPTp9IvMb2m4Aco2MprfnbiQ', {action: $form.find('input[name="action"]').val()}).then(function (token) {
                  console.log('Executing RECAPTCHA');
                  var postData = {token: token};
                  fetch('/validate-form', {
                    method: 'post',
                    body: JSON.stringify(postData),
                    headers:{
                      'Content-Type': 'application/json'
                    }
                  }).then(function (response) {
                    response.json().then(function (data) {
                      console.log("Rating:"+data.score);
                      if (data.success === true && (data.score >= 0.7)) {
                        console.log('Adding element');
                        $form.prepend('<input type="hidden" name="g-rec-field" value="TRUE">');
                        console.log("submitting");
                        form.submit();
                      }
                    });
                  });
                });
              },
            });
          });
        });
      }
    };
    validateForm();

}

var windowPosition;

function navigation(){

    $(".mobile_menu_icon").on("click", function(){
        if(!$(this).hasClass("active")){
            showNav();
        } else{
            hideNav();
        }
        return false;
    });

    $("#mobile_nav_overlay").on("click", function(){

        hideNav();
        return false;
    });

    fixedNavigation();

}

var showNav = function(){

    $(".mobile_menu_icon").addClass("active");

    // The nav

    TweenLite.set(document.getElementById("mobile_nav"), {
        opacity: 0,
        x: "120%",
        display: "none"
    });

    TweenLite.to(document.getElementById("mobile_nav"), 0.5, {
        opacity: 1,
        x: "0%",
        autoAlpha: 1,
        force3D: true,
        display: "block",
        ease: Back.easeInOut
    });

    // The nav list items
    var navItems = document.getElementById("mobile_nav").getElementsByTagName("li");
    var delay = 0.1;

    for(var i=0;i<navItems.length;i++){

        TweenLite.set(navItems[i], {
            opacity: 0,
            x: "20%",
            display: "none"
        });

        TweenLite.to(navItems[i], 0.3, {
            opacity: 1,
            x: "0%",
            autoAlpha: 1,
            force3D: true,
            display: "block",
            ease: Back.easeInOut,
            delay: delay
        });

        delay = (delay+0.1);

    }

    // The overlay
    TweenLite.set(document.getElementById("mobile_nav_overlay"), {
        opacity: 0,
        display: "none"
    });

    TweenLite.to(document.getElementById("mobile_nav_overlay"), 0.3, {
        opacity: 1,
        autoAlpha: 1,
        force3D: true,
        display: "block",
        ease: Back.easeInOut
    });

    // saving window position
    windowPosition = $(window).scrollTop();
    $(".page_wrapper").addClass("noscrolling").css("top", "-"+windowPosition+"px");

};

var hideNav = function(){

    $(".mobile_menu_icon").removeClass("active");

    // The nav

    TweenLite.to(document.getElementById("mobile_nav"), 0.5, {
        opacity: 0,
        x: "120%",
        autoAlpha: 1,
        force3D: true,
        display: "none",
        ease: Back.easeInOut
    });

    // The overlay

    TweenLite.to(document.getElementById("mobile_nav_overlay"), 0.3, {
        opacity: 0,
        autoAlpha: 1,
        force3D: true,
        display: "none",
        ease: Back.easeInOut
    });

    // resetting window position
    $(".page_wrapper").removeClass("noscrolling").css("top", "0px");
    $(window).scrollTop(windowPosition);

};

function fixedNavigation(){
    var waypoint = new Waypoint({
        element: document.getElementById('top'),
        handler: function(direction) {
            if(direction==="down"){
                document.getElementById('fixed_header').className += " active";
                TweenLite.set(document.getElementById("fixed_header"), {
                    y: "-120%",
                    display: "block"
                });
                TweenLite.to(document.getElementById("fixed_header"), 0.5, {
                    y: "0%",
                    force3D: true,
                    ease: Back.easeInOut
                });

            } else {
                document.getElementById('fixed_header').className = document.getElementById('header').className.replace(" active", "");
                TweenLite.to(document.getElementById("fixed_header"), 0.5, {
                    y: "-120%",
                    force3D: true,
                    ease: Back.easeInOut
                });

            }
        }
    });
}

function lightbox(){
    if($('.page_content_blocks .page_content_block.image .lightbox').length){
        $('.page_content_blocks .page_content_block.image .lightbox').magnificPopup({
            type: 'image',
            gallery:{
                enabled:true
            }
        });
        console.log("lightbox");
    }
}

function scroll(){

    function getParameterByName(name, url) {
        if (!url) {
          url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    var scroll = getParameterByName("scroll");
    if(scroll){
        setTimeout(function(){
            var scrollPosition = ($("#"+scroll).offset().top-$("#fixed_header").outerHeight(true, true)-40);
            TweenLite.to(window, 0.5,{
                scrollTo: scrollPosition
            });
        }, 300);
    }
}


// Imports
function caseStudyList(){
    $(".case_study_list_wrapper .case_study h2 a").on("click", function(){
        return false;
    });
}


// Pages
function homePage(){

    if($('.home_slider').length){
        $('.home_slider').slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            cssEase: 'cubic-bezier(0.86, 0, 0.07, 1)',
            prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        arrows: true
                    },
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 580,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ]
        });
    }

}

function faqsList(){

    if($(".question_wrapper a").length){
        $(".question_wrapper a").on('click', function(){
            if(!$(this).parents('.faq_wrapper').hasClass('active')){
                $(this).parents('.faq_wrapper').addClass("active").find(".fa").addClass("fa-close").removeClass("fa-plus");
            } else{
                $(this).parents('.faq_wrapper').removeClass("active").find(".fa").removeClass("fa-close").addClass("fa-plus");
            }
            return false;
        });
    }

}


// Forms
function rmcForm(){

    if($('form#reviewMyClaimForm').length){

        $('.has-your-case-been-settled-already label').on('click', function() {
            if(!$(this).hasClass('active')){
                $('.has-your-case-been-settled-already label').removeClass('active');
                $(this).addClass('active');
            }
        });

        $('.keep_up_to_date label').on('click', function() {
            if(!$(this).hasClass('active')){
                $(this).addClass('active');
            } else{
                $(this).removeClass('active');
            }
        });

    }

    const urlParams = new URLSearchParams(window.location.search);
    const callbackSuccess = urlParams.get('message');
    if (callbackSuccess && callbackSuccess === 'thankyou') {
        const tracking = {
            event: 'form_submission',
            form_handle: 'reviewMyClaimForm',
        };

        window.dataLayer = window.dataLayer || [];

        dataLayer.push(tracking);
    }

}

function callbackForm() {
  if ($(".page_tiles").length) {
    $(".page_tiles li").each(function (index, element) {
      var $element = $(element);
      var $a = $element.find('a');
      var $magnific;

      if ($a.attr("href") == "#callback_form") {
        var hasError = $a.hasClass('has-error');

        $magnific = $a.magnificPopup({
          type: 'inline',
          midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        });

        if (hasError) {
          $magnific.magnificPopup('open');
        }

        $element.find(".button_close").on("click", function () {
          $magnific.close();
        });
      }
    });
  }

  const urlParams = new URLSearchParams(window.location.search);
  const callbackSuccess = urlParams.get('callback');
  if (callbackSuccess && callbackSuccess === 'thankyou') {
    const tracking = {
      event: 'form_submission',
      form_handle: 'callBackForm',
    };

    window.dataLayer = window.dataLayer || [];

    dataLayer.push(tracking);
  }
}


// IE
function ie(){

    if($("html").hasClass("ie9, oldie")){

        // $('.service_tiles ul li .icon').each(function(index,element) {
        //   element.src = element.src.replace('.svg','.png');
        //   console.log(element.src);
        // });

    }


  $('svg').each(function() {
    var svg = $(this);
    var text = svg.find('text');
    var bbox = text.get(0).getBBox();
    //the hack
    var calcString = "calc(100% * " + bbox.height/bbox.width + ")";
    svg.css("padding-bottom",calcString);

    svg.get(0).setAttribute('viewBox',
                           [bbox.x,
                            bbox.y,
                            bbox.width,
                            bbox.height].join(' '));
  });



}


$(document).on("ready", function(){
    caseStudyList();
    homePage();
    faqsList();

    formValidation();
    rmcForm();
    callbackForm();
    navigation();
    lightbox();
    scroll();
    ie();

});
